import React from 'react';
import Media, { QueryResults } from 'react-media';

export type Size = QueryResults<{
  lg: string;
  xs: string;
  md: string;
  sm: string;
  [breakpoint: string]: string;
}>;
interface MediaContainerProps {
  children: (size: Size) => React.ReactChild;
  customBreakpoints?: any;
}

const MediaContainer: React.FC<MediaContainerProps> = ({ children, customBreakpoints }) => {
  return (
    <Media
      queries={{
        xs: '(max-width:575px)',
        sm: '(min-width: 576px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 992px)',
        ...customBreakpoints,
      }}
      defaultMatches={{ lg: true }}
    >
      {size => children(size)}
    </Media>
  );
};

export default MediaContainer;

// USAGE
/*

    <MediaContainer>
        {(size: Size)=>(<> Your JSX Content goes here </>)}
    </MediaContainer>
*/
