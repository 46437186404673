import { graphql, useStaticQuery } from 'gatsby';
import { GlobalStrings } from '../fragments/MRKConfig';
import { useLocale } from './useLocale';

const staticStringsQuery = graphql`
  query {
    allMrkConfigStaticStrings {
      nodes {
        ...GlobalStringsFragment
      }
    }
  }
`;

export function useStaticStrings(isEnglishOnly?: boolean) {
  const locale = useLocale();
  const result = useStaticQuery(staticStringsQuery);
  const nodes: GlobalStrings[] = result?.allMrkConfigStaticStrings?.nodes || [];
  const englishStrings = nodes.find(globalStrings => globalStrings.locale === 'en-US');

  if (!englishStrings) {
    throw new Error('Could not find en-US locale global strings entry');
  }

  if (isEnglishOnly) return englishStrings;
  return nodes.find(globalStrings => globalStrings.locale === locale) || englishStrings;
}
