import { useLocation } from '@reach/router';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextColumnContent,
  ContentfulRichTextColumnContentDataTarget,
} from 'src/contentful/content-types/blades/blade-richtext-columns/blade-richtext-column.interface';
import { locales } from '../../util/locales';
import { PREVIEW_ENTRY } from './cmsState.interface';

export const useQuery = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

const resolveEmbeddedRichtext = (
  target: ContentfulRichTextColumnContentDataTarget
): ContentfulRichTextColumnContentDataTarget => {
  if (!target) return target;
  if (target.sys.type === 'Asset') {
    return {
      ...target,
      related_asset: resolvePreviewApiObject(target),
    };
  }
  if (target.sys.type === 'Entry') {
    const newTarget = {
      ...target,
    };
    if (target.sys.contentType?.sys.id === 'button') {
      newTarget.entry_button = resolvePreviewApiObject(target);
    }
    if (target.sys.contentType?.sys.id === 'assetFile') {
      newTarget.entry_assetFile = resolvePreviewApiObject(target);
    }
    if (target.sys.contentType?.sys.id === 'elementIcon') {
      newTarget.entry_elementIcon = resolvePreviewApiObject(target);
    }
    if (target.sys.contentType?.sys.id === 'elementLink') {
      newTarget.entry_elementLink = resolvePreviewApiObject(target);
    }
    if (target.sys.contentType?.sys.id === 'page') {
      newTarget.entry_page = resolvePreviewApiObject(target);
    }
    return newTarget;
  }
  return target;
};

const resolveRichtext = (
  richtextContent: ContentfulRichTextColumnContent
): ContentfulRichTextColumnContent => {
  const requiresResolve = [
    BLOCKS.EMBEDDED_ASSET,
    BLOCKS.EMBEDDED_ENTRY,
    INLINES.ASSET_HYPERLINK,
    INLINES.EMBEDDED_ENTRY,
    INLINES.ENTRY_HYPERLINK,
  ];
  if (requiresResolve.includes(richtextContent?.nodeType)) {
    return {
      ...richtextContent,
      data: {
        ...richtextContent.data,
        target: resolveEmbeddedRichtext(richtextContent.data.target),
      },
    };
  }
  return richtextContent;
};

export function isPreviewEntryArray(entries: any = {}): entries is PREVIEW_ENTRY[] {
  return Array.isArray(entries) && entries.some(value => isPreviewEntry(value));
}

export function isPreviewEntry(entry: any = {}): entry is PREVIEW_ENTRY {
  const { fields, metadata, sys } = entry;
  return entry && fields && metadata && sys;
}

export const resolveRichtextReccursive = (previewFields: any, resolver: any) => {
  const resolved = resolver(previewFields);
  for (var k in resolved) {
    if (k === 'content') {
      resolved[k] = resolved?.[k]?.map((content: ContentfulRichTextColumnContent) =>
        resolveRichtextReccursive(content, resolver)
      );
    }
  }
  return resolved;
};

export const resolvePreviewApiObject = (entryObj: any) => {
  const previewSys = {
    contentTypeId: entryObj?.sys?.contentType?.sys?.id || entryObj?.sys?.type,
    contentfulId: entryObj?.sys?.id,
    id: entryObj?.sys?.id,
  };
  const previewFields = { ...entryObj?.fields };

  if (previewFields.file?.url) {
    previewFields.file.publicURL = `https:${previewFields.file.url}`;
  }

  if (previewFields.brandfolderAsset?.length) {
    previewFields.brandfolderAsset = {
      file: {
        publicURL: previewFields.brandfolderAsset[0]?.cdn_url,
      },
      height: previewFields.brandfolderAsset[0]?.height,
      size: previewFields.brandfolderAsset[0]?.size,
      width: previewFields.brandfolderAsset[0]?.width,
    };
  }

  if (previewFields?.column?.nodeType === 'document') {
    previewFields.column = resolveRichtextReccursive(previewFields.column, resolveRichtext);
  }

  const previewData = {
    ...previewSys,
    ...previewFields,
  };

  if (previewData.contentTypeId === 'localeList') {
    Object.keys(previewData)?.forEach((key: string) => {
      const localeKeys = locales?.map(locale => locale.replace('-', ''));
      if (localeKeys.includes(key)) {
        previewData[key] = 'Translated for Locale';
      }
    });
  }

  return previewData;
};

export const resolveTopLevelEntry = (entryObj: any, resolver: any) => {
  const resolved = resolver(entryObj);
  for (var k in resolved) {
    if (isPreviewEntry(resolved[k])) {
      const resolvedPreview = resolveTopLevelEntry(resolved[k], resolver);
      resolved[k] = { ...resolvedPreview };
    }
    if (isPreviewEntryArray(resolved[k])) {
      resolved[k] = resolved[k].map((item: any) => resolveTopLevelEntry(item, resolver));
    }
  }
  return resolved;
};
