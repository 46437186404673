import { ContentfulClientApi } from 'contentful';
import React, { useEffect, useState, useRef } from 'react';
import { useLocale } from '../useLocale';
import { useContentfulClient } from './cmsState.client';
import { resolvePreviewApiObject, resolveTopLevelEntry } from './cmsState.utils';
import { usePreviewMode } from './usePreviewMode';

export const useCMSState = <T extends { contentfulId: string }>(initial: T): T => {
  const [state, setState] = useState<T>(initial);
  const isPreviewMode = usePreviewMode();
  const client = useContentfulClient();
  const locale = useLocale();

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const getPreviewContent = async () => {
      try {
        const entry = await (client as ContentfulClientApi).getEntry(state.contentfulId, {
          include: 10,
          locale,
        });
        const entryObj: any = entry.toPlainObject();
        const entryFields = resolveTopLevelEntry(entryObj, resolvePreviewApiObject);
        if (entryFields && isPreviewMode) {
          setState({
            ...entryFields,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isPreviewMode) {
      getPreviewContent();
      timer.current = setInterval(() => getPreviewContent(), 10000);
    }
    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (!isPreviewMode) {
      setState({ ...initial });
      timer.current && clearInterval(timer.current);
    }
  }, [isPreviewMode]);

  return state;
};
