import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import RefreshedFooter from './refreshed-footer';
import AppContextProvider from '../common/context/app.context';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { HrefLangs } from './refreshed-header/HrefLangs';
import { makeStyledComponent, Div } from '../common/Elements/index';
import { MaybeRedwood } from '../../config';
import { useLocale } from '../../common/hooks/useLocale';
import { ContentfulPsaBanner } from './ContentfulPsaBanner';
import { psaBannerInterface } from '../../contentful/content-types/psa-banner';
import loadable from '@loadable/component';
import { usePreviewMode } from '../../common/hooks/useCMSState/usePreviewMode';
import { MainFooter } from './refreshed-footer/footer.interfaces';
import { SiteMetadata, useConfiguration } from '../../common/hooks/useConfiguration';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';
import { useTranslations } from '../../common/hooks/useTranslations';
import { TopNavOptions } from '../../contentful/content-types/page/page.interface';
import { CustomCTAButton } from './refreshed-header/components/header.interface';
import { PersonalizerContextContainer } from '../common/Personalizer/PersonalizerContextContainer';

const PreviewBanner = loadable(() => import('./PreviewBanner'));
const PreviewFAB = loadable(() => import('./../common/Personalizer/PreviewFAB'));
const PreviewWindow = loadable(() => import('../common/Personalizer/PreviewWindow'));
const Header = loadable(() => import('./refreshed-header'));

Modal.setAppElement('#___gatsby');

const Container = makeStyledComponent(({ children }) => (
  <Div display="flex" flexDirection="column" width="100%">
    {children}
  </Div>
));

export interface LayoutProps {
  pageContext: AppContextProps;
  customNav?: ContentfulNavNavigationGroup[];
  hrefLangs?: ReturnType<typeof HrefLangs>;
  footerData: MainFooter;
  headerData: ContentfulNavNavigationGroup;
  refreshStyles?: boolean;
  psaBanner?: psaBannerInterface;
  customHeaderLogoUrl?: string | undefined;
  topNavOptions?: TopNavOptions;
  customCTAButton?: CustomCTAButton;
}

const environmentScripts: {
  [K in Exclude<SiteMetadata['targetEnv'], undefined>]: JSX.Element | null;
} = {
  production: (
    <script
      async
      src="https://www.cloudflare.com/vendor/onetrust/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231"
    ></script>
  ),
  staging: (
    <script
      async
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
    ></script>
  ),
  development: (
    <script
      async
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
    ></script>
  ),
  preview: (
    <script
      async
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
    ></script>
  ),
  test: null,
};

const Layout: FC<LayoutProps> = ({
  children,
  customNav,
  pageContext,
  footerData,
  headerData,
  psaBanner,
  customHeaderLogoUrl,
  topNavOptions,
  customCTAButton,
  hrefLangs = HrefLangs(pageContext.locale, pageContext.pathname, pageContext.baseURL),
}) => {
  const [countryCode, setCountryCode] = React.useState('US');
  const { targetEnv } = useConfiguration();
  const isPreviewMode = usePreviewMode();
  const translate = useTranslations();

  useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setCountryCode(redwood?.country || countryCode);
    //add Smartling tracking script in staging
    if (process.env.TARGET_ENV === 'staging') {
      const script = document.createElement('script');
      script.src = `//d2c7xlmseob604.cloudfront.net/tracker.min.js`;
      document.body.appendChild(script);
      script.onload = () => {
        (window as any).SmartlingContextTracker?.init({ orgId: 'XUfaSSIllQKXtFXrL73krA' });
      };
    }
  }, []);

  const getNewNavGroup = (
    nav?: ContentfulNavNavigationGroup
  ): ContentfulNavNavigationGroup | undefined => {
    const isNewNav = nav?.contentTypeId === 'navNavigationGroup';
    if (!isNewNav) {
      return undefined;
    }
    return nav;
  };

  return (
    <AppContextProvider pageContext={pageContext}>
      <PersonalizerContextContainer>
        <Container>
          <Helmet>
            <title>Cloudflare - The Web Performance &amp; Security Company | Cloudflare</title>
            <meta
              name="description"
              content="Here at Cloudflare, we make the Internet work the way it should. Offering CDN, DNS, DDoS protection and security, find out how we can help your site."
            ></meta>
            <meta
              name="title"
              content="Cloudflare - The Web Performance &amp; Security Company | Cloudflare"
            ></meta>
            <meta name="twitter:creator" content="@cloudflare" />
            <meta name="twitter:site" content="@cloudflare"></meta>
            <meta property="og:site_name" content="Cloudflare"></meta>
            {hrefLangs}
            {environmentScripts[targetEnv || 'development']}
            {targetEnv === 'development' && (
              <script
                async
                src="https://www.googleoptimize.com/optimize.js?id=GTM-N4JSZJ8"
              ></script>
            )}
            <html lang={useLocale().toLowerCase()} />
          </Helmet>
          {isPreviewMode && <PreviewBanner />}
          {psaBanner && <ContentfulPsaBanner banner={psaBanner} />}
          {process.env.TARGET_ENV !== 'production' && (
            <>
              <PreviewFAB />
              <PreviewWindow />
            </>
          )}
          <Header
            headerData={headerData}
            customNav={getNewNavGroup(customNav?.[0])}
            customCTAButton={customCTAButton}
            topNavOption={topNavOptions}
            customHeaderLogoUrl={customHeaderLogoUrl}
            localizedStrings={{
              underAttack: translate('Under Attack?'),
              phoneNumberWithColon: translate('sales_with_colon'),
              Contact_Sales: translate('Contact_Sales'),
              Back: translate('Back'),
              Featured: translate('Featured'),
            }}
          />
          <div className="site-content">{children}</div>
          <RefreshedFooter footerData={footerData} />
        </Container>
      </PersonalizerContextContainer>
    </AppContextProvider>
  );
};

export default Layout;
