import * as React from 'react';
import { Table } from './Elements';

interface TableProps {
  content: string;
}

export const TableSimple: React.FC<TableProps> = ({ content }) => {
  return (
    <Table border="all" fontSize={3} textAlign="center" width={'100%'} borderCollapse="collapse">
      {content}
    </Table>
  );
};
