import * as React from 'react';
import { Pre, Code } from './Elements';

interface CodeBlockProps {
  content: string;
}

export const CodeBlock: React.FC<CodeBlockProps> = ({ content }) => {
  return (
    <Pre backgroundColor="gray4" border="all" borderColor="gray2" ph={4} pv={3}>
      <Code fontWeight={7} color="black" lineHeight={1}>
        {content}
      </Code>
    </Pre>
  );
};
