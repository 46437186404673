import { ContentfulClientApi, createClient } from 'contentful';

export const useContentfulClient = (): ContentfulClientApi => {
  const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID || '',
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN_PREVIEW || '',
    host: 'preview.contentful.com',
  });
  return client;
};
