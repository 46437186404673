import * as React from 'react';
import { MarkdownBlock } from '../common/MarkdownBlock';
import { Icon } from '../common/Icon';
import { A, P, Section, Container, Button, Div, Span } from '../common/Elements';
import { useContentfulPsaBanner } from '../../common/hooks/useContentfulPsaBanner';
import { psaBannerInterface } from '../../contentful/content-types/psa-banner';
import { RelatedBlade } from '../../contentful/blades';
import MediaContainer, { Size } from '../common/MediaContainer';

export function isContentfulPsaBanner(blade: RelatedBlade): blade is psaBannerInterface {
  return blade.contentTypeId === 'psaBanner';
}

interface PSABannerProps {
  banner: psaBannerInterface;
}

export const ContentfulPsaBanner: React.FC<PSABannerProps> = ({ banner }) => {
  const [psaBanner, closeBanner] = useContentfulPsaBanner(banner);
  return (
    <MediaContainer customBreakpoints={{ xxs: '(max-width:410px)', laptop: '(max-width:1024px)' }}>
      {(size: Size) => (
        <>
          {psaBanner && !psaBanner.isPsaBannerClosed && (
            <Section
              backgroundColor={psaBanner.backgroundColor || 'blue0'}
              color="white"
              display="flex"
              flexWrap="wrap"
              justifyContent={['around', 'center']}
              className="topnav"
              id="contentful-psa-banner"
            >
              <Div
                display="flex"
                justifyContent={'between'}
                margin={0}
                marginRight={size.xxs ? 2 : 'auto'}
                position="relative"
                alignItems="center"
                className="top-nav__items top-nav__items--align-content mr1 mr0-ns"
                width="100%"
                marginVertical={0}
                paddingHorizontal={[3, 3, 3, 6]}
                style={{
                  maxWidth: '1440px',
                  margin: '0 auto',
                  flexWrap: 'nowrap',
                }}
              >
                <Div display={'flex'} flexGrow={1} flexDirection={['column', 'row']}>
                  <MarkdownBlock
                    display="flex"
                    flex="auto"
                    marginRight={[0, 4]}
                    source={psaBanner.text}
                    renderers={{
                      paragraph: ({ children }) => (
                        <Div
                          display="flex"
                          color="white"
                          alignItems="start"
                          justifyContent="center"
                          position="relative"
                          flexDirection={['column', 'row']}
                          flexGrow={1}
                          fontSize={2}
                          paddingVertical={1}
                        >
                          <Div>
                            <Span>
                              <Span
                                style={{
                                  marginRight: '4px',
                                }}
                              >
                                {children}
                              </Span>
                              <A
                                href={banner?.learnMoreUrl}
                                display={['none', 'inline-flex']}
                                textAlign="left"
                                alignItems="center"
                                hovered={{ color: 'blue5' }}
                                color="white"
                                fontWeight={6}
                                fontSize={2}
                              >
                                {banner?.learnMoreText} <Icon className="ml1" type="chevron" />
                              </A>
                            </Span>
                            <A
                              href={banner?.learnMoreUrl}
                              display={['inline', 'none']}
                              textAlign="left"
                              alignItems="center"
                              hovered={{ color: 'blue5' }}
                              color="white"
                              fontWeight={6}
                              fontSize={2}
                            >
                              <Span
                                fontWeight={6}
                                fontSize={2}
                                id={size.xxs ? 'psa-learnmore' : ''}
                                display="inline-block"
                              >
                                {banner.learnMoreText}
                                <Icon className="ml1l di" type="chevron" />
                              </Span>
                            </A>
                          </Div>
                        </Div>
                      ),
                    }}
                  />
                </Div>

                {!banner.hideCloseButton && (
                  <Button
                    lineHeight={1}
                    padding={0}
                    onClick={closeBanner}
                    // position="absolute"
                    // style={{ right: 0 }}

                    backgroundColor="transparent"
                    style={{ cursor: 'pointer' }}
                  >
                    <Icon type="x" fill="white" width={2} height={2} />
                  </Button>
                )}
              </Div>
            </Section>
          )}
        </>
      )}
    </MediaContainer>
  );
};
