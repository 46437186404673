import loadable from '@loadable/component';
import React from 'react';
const PersonalizerProvider = loadable(() =>
  import('../../../contentful/content-types/personalization/personalization.context')
);
export const PersonalizerContextContainer: React.FC = ({ children }) => {
  if (process.env.TARGET_ENV === 'production') {
    return <>{children}</>;
  } else {
    return <PersonalizerProvider>{children}</PersonalizerProvider>;
  }
};
