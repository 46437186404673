import { graphql, useStaticQuery } from 'gatsby';

export interface SiteMetadata {
  targetEnv?: 'development' | 'staging' | 'test' | 'production' | 'preview';
  coreApiV1: string;
}

const configQuery = graphql`
  query {
    site {
      siteMetadata {
        targetEnv
        coreApiV1
      }
    }
  }
`;

export function useConfiguration() {
  const result = useStaticQuery(configQuery);
  const metadata = result?.site.siteMetadata as SiteMetadata;

  return metadata;
}
